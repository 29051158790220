var render = function () {
  var _vm$selectedTableData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [!_vm.isBuyer ? _c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('el-switch', {
    staticClass: "mb-2",
    attrs: {
      "active-text": "Show file uploader"
    },
    model: {
      value: _vm.isShowUploader,
      callback: function ($$v) {
        _vm.isShowUploader = $$v;
      },
      expression: "isShowUploader"
    }
  }), _vm.isShowUploader ? [_c('el-upload', {
    ref: "uploader",
    staticClass: "upload-demo",
    attrs: {
      "drag": "",
      "action": "",
      "auto-upload": false,
      "on-change": _vm.handleFileChanged,
      "file-list": _vm.fileList,
      "multiple": "",
      "accept": ".png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, xlsx, .doc, .docx, .ppt, .pptx, .pdf"
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drop file here or "), _c('em', [_vm._v("click to upload")])]), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(" Accept files with extension .png, .gif, .jpg, .jpeg, .bmp, .csv, .txt, .xlx, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf ")])]), _vm.fileList.length > 0 ? _c('el-button', {
    staticClass: "mb-4",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "icon": "el-icon-upload",
      "loading": _vm.isBtnLoading
    },
    on: {
      "click": _vm.uploadFile
    }
  }, [_vm._v(" Upload ")]) : _vm._e()] : _vm._e()], 2) : _vm._e(), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "height": "550",
      "data": _vm.documentBidsTable,
      "row-key": "document-bids-table"
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "Name",
      "min-width": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('a', {
          attrs: {
            "target": "_blank",
            "href": scope.row.file_url
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "label": "Type",
      "min-width": "50"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "size",
      "label": "Size",
      "min-width": "50"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "uploaded_at",
      "label": "Uploaded At"
    }
  }), !_vm.isBuyer ? _c('el-table-column', {
    attrs: {
      "prop": "isDefaultFile",
      "label": "Default",
      "sortable": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.isDefaultFile ? _c('mdicon', {
          attrs: {
            "name": "check",
            "size": 20
          }
        }) : _c('mdicon', {
          attrs: {
            "name": "minus",
            "size": 20
          }
        })];
      }
    }], null, false, 816000705)
  }) : _vm._e(), !_vm.isBuyer ? _c('el-table-column', {
    attrs: {
      "align": "right",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [!scope.row.isDefaultFile ? _c('el-button', {
          staticStyle: {
            "height": "100%"
          },
          on: {
            "click": function ($event) {
              return _vm.openDialog('add-default', scope.row);
            }
          }
        }, [_vm._v(" Add to default ")]) : _vm._e(), _c('el-button', {
          on: {
            "click": function ($event) {
              return _vm.openDialog('delete-file', scope.row);
            }
          }
        }, [_c('mdicon', {
          staticClass: "text-danger",
          attrs: {
            "name": "trash-can",
            "size": 16
          }
        })], 1)];
      }
    }], null, false, 4086983796)
  }) : _vm._e()], 1), _c('el-dialog', {
    attrs: {
      "title": "Add file to default",
      "visible": _vm.isAddDefaultDialogOpen,
      "width": "30%",
      "center": "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.isAddDefaultDialogOpen = $event;
      }
    }
  }, [_c('span', [_vm._v("Are you sure to add this file as default?")]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.isAddDefaultDialogOpen = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.addToDefault
    }
  }, [_vm._v(" Yes, sure ")])], 1)]), _c('el-dialog', {
    attrs: {
      "title": "Delete",
      "visible": _vm.isDeleteDialogOpen,
      "width": "30%",
      "center": "",
      "append-to-body": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.isDeleteDialogOpen = $event;
      }
    }
  }, [_c('p', [_vm._v("Are you sure to delete this file? This action cannot be undone.")]), (_vm$selectedTableData = _vm.selectedTableData) !== null && _vm$selectedTableData !== void 0 && _vm$selectedTableData.isDefaultFile ? _c('el-checkbox', {
    model: {
      value: _vm.selectedTableData.deleteFromDefaultFile,
      callback: function ($$v) {
        _vm.$set(_vm.selectedTableData, "deleteFromDefaultFile", $$v);
      },
      expression: "selectedTableData.deleteFromDefaultFile"
    }
  }, [_vm._v(" Also delete from default file ")]) : _vm._e(), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.isDeleteDialogOpen = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.isBtnLoading
    },
    on: {
      "click": function ($event) {
        return _vm.deleteFile(_vm.selectedTableData);
      }
    }
  }, [_vm._v(" Yes, Delete ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }